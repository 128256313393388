import React from 'react';

const Disclaimer = () => {
    return (
        <div style={{ padding: '20px', backgroundColor: '#f9f9f9', borderRadius: '8px', marginTop: '20px' }}>
            <h2>Disclaimer</h2>
            
            <h3>Website Disclaimer</h3>
            <p>The information provided by <strong>Jagarani.in</strong> ("we," "us," or "our") on this website is for general informational purposes only. All information on the Site is provided in good faith, however, we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the Site.</p>
            <p>Under no circumstance shall we have any liability to you for any loss or damage of any kind incurred as a result of the use of the site or reliance on any information provided on the site. Your use of the site and your reliance on any information on the site is solely at your own risk.</p>
            
            <h3>Event Disclaimer</h3>
            <p><strong>Jagarani.in</strong> organizes various social activities, including fairs, cultural events, and other community-based activities. Participation in these events is voluntary. We do not assume any responsibility or liability for any harm, injury, or loss that may occur during or as a result of participation in these events. Participants are advised to take appropriate precautions and understand that they participate at their own risk.</p>
            
            <h3>External Links Disclaimer</h3>
            <p>The Site may contain (or you may be sent through the Site) links to other websites or content belonging to or originating from third parties or links to websites and features in banners or other advertising. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability, or completeness by us. We do not warrant, endorse, guarantee, or assume responsibility for the accuracy or reliability of any information offered by third-party websites linked through the site or any website or feature linked in any banner or other advertising. We will not be a party to or in any way be responsible for monitoring any transaction between you and third-party providers of products or services.</p>
            
            <h3>Limitation of Liability</h3>
            <p>In no event shall <strong>Jagarani.in</strong> or its organizers be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your use or access to or inability to use or access the site; (ii) any content obtained from the site; or (iii) unauthorized access, use, or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence), or any other legal theory, whether or not we have been informed of the possibility of such damage.</p>
            
            <h3>Changes to This Disclaimer</h3>
            <p>We reserve the right to update or change this Disclaimer at any time and you should check this Disclaimer periodically. Your continued use of the Site after we post any modifications to the Disclaimer will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Disclaimer.</p>
        </div>
    );
};

export default Disclaimer;
