import React, { useState } from 'react';
import { db } from './firebase'; // Import the Firestore instance
import { collection, addDoc } from 'firebase/firestore';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '', // Add phone to the form data state
    subject: '',
    message: ''
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Reference to the 'contacts' collection in Firestore
      await addDoc(collection(db, 'contacts'), formData);

      alert('Message sent successfully!');

      // Reset form fields
      setFormData({
        name: '',
        email: '',
        phone: '', // Reset the phone field
        subject: '',
        message: ''
      });
    } catch (error) {
      console.error('Error sending message:', error);
      alert('Failed to send message.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <h1 className="display-4 text-center mb-4">Contact Us</h1>
          <p className="lead text-center mb-4">
            We'd love to hear from you! Whether you have a question about our events, sports activities, or anything else, our team is ready to answer all your questions.
          </p>
          
          <form onSubmit={handleSubmit}>
            <div className="form-group mb-3">
              <label htmlFor="name" className="form-label">Name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter your name"
                required
              />
            </div>

            <div className="form-group mb-3">
              <label htmlFor="email" className="form-label">Email address</label>
              <input
                type="email"
                className="form-control"
                id="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter your email"
                required
              />
            </div>

            <div className="form-group mb-3">
              <label htmlFor="phone" className="form-label">Phone Number</label>
              <input
                type="tel"
                className="form-control"
                id="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="Enter your phone number"
                required
              />
            </div>

            <div className="form-group mb-3">
              <label htmlFor="subject" className="form-label">Subject</label>
              <input
                type="text"
                className="form-control"
                id="subject"
                value={formData.subject}
                onChange={handleChange}
                placeholder="Enter subject"
                required
              />
            </div>

            <div className="form-group mb-3">
              <label htmlFor="message" className="form-label">Message</label>
              <textarea
                className="form-control"
                id="message"
                value={formData.message}
                onChange={handleChange}
                rows="5"
                placeholder="Enter your message"
                required
              ></textarea>
            </div>

            <div className="text-center">
              <button type="submit" className="btn btn-primary btn-lg" disabled={loading}>
                {loading ? 'Sending...' : 'Send Message'}
              </button>
            </div>
          </form>
          
          <div className="mt-5 text-center">
            <h3>Contact Information</h3>
            <p><strong>Address:</strong>  PURBA BISHNUPUR , CHAKDAH, NADIA</p>
            <p><strong>Business Name:</strong> Mukesh Halder</p>
            <p><strong>Phone:</strong>9832222142/9564453080/9434824662</p>
            <p><strong>Pin:</strong>741223</p>
            <p><strong>Email:</strong> jagarani1957@gmail.com</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
