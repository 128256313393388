import React from 'react';
import { Navbar, Nav, Container, Dropdown } from 'react-bootstrap';
import './Hcss.css';

const Header = () => {
  return (
    <Navbar variant="dark" expand="lg" fixed="top" className="navbar-with-hanging-logo">
      <Container>
        <Navbar.Brand href="/">
          <div className="logo-container">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/jagarani-c3a5c.appspot.com/o/logo192.jpeg?alt=media&token=67d16203-6067-40a7-ba6d-af5ae4d95149"
              alt="Logo"
              className="hanging-logo"
            />
          </div>
          <span className="brand-name">জাগরণী</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Dropdown>
              <Dropdown.Toggle variant="link" id="dropdown-student-registration">
                Student Corner
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="/student">Online Form </Dropdown.Item>
                <Dropdown.Item href="/search">Print Admit</Dropdown.Item>
                
              </Dropdown.Menu>
            </Dropdown>
            
            <Nav.Link href="/about">About</Nav.Link>
            <Nav.Link href="/Events">Events</Nav.Link>
            <Nav.Link href="/contact">Contact Us</Nav.Link>
            
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
