import React from 'react';
import { Container, Row, Col, Card, ListGroup } from 'react-bootstrap';

const PrivacyPolicy = () => {
  return (
    <Container className="my-4">
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>Privacy Policy</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">Last updated: August 12, 2024</Card.Subtitle>
              <Card.Text>
                This Privacy Policy describes our policies and procedures on the collection, use, and disclosure of your information when you use the Service. It also informs you about your privacy rights and how the law protects you.
              </Card.Text>

              <Card.Title>Interpretation and Definitions</Card.Title>
              
              <Card.Subtitle className="mb-2 text-muted">Interpretation</Card.Subtitle>
              <Card.Text>
                The words with initial letters capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or plural.
              </Card.Text>

              <Card.Subtitle className="mb-2 text-muted">Definitions</Card.Subtitle>
              <ListGroup>
                <ListGroup.Item>
                  <strong>Account</strong> means a unique account created for you to access our Service or parts of our Service.
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Affiliate</strong> means an entity that controls, is controlled by, or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest, or other securities entitled to vote for the election of directors or other managing authority.
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Company</strong> (referred to as either "the Company", "We", "Us", or "Our" in this Agreement) refers to jagarani.in.
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Cookies</strong> are small files placed on your computer, mobile device, or any other device by a website, containing details of your browsing history on that website among other uses.
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Country</strong> refers to West Bengal, India.
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Device</strong> means any device that can access the Service such as a computer, cellphone, or digital tablet.
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Service</strong> refers to the Website.
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Service Provider</strong> means any natural or legal person who processes data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, provide the Service on behalf of the Company, perform services related to the Service, or assist the Company in analyzing how the Service is used.
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Third-party Social Media Service</strong> refers to any website or social network through which a user can log in or create an account to use the Service.
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (e.g., the duration of a page visit).
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>You</strong> means the individual accessing or using the Service, or the company or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
                </ListGroup.Item>
              </ListGroup>

              <Card.Title className="mt-4">Collecting and Using Your Personal Data</Card.Title>

              <Card.Subtitle className="mb-2 text-muted">Types of Data Collected</Card.Subtitle>
              <Card.Text>
                <strong>Personal Data</strong> While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to:
              </Card.Text>
              <ListGroup>
                <ListGroup.Item>Email address</ListGroup.Item>
                <ListGroup.Item>First name and last name</ListGroup.Item>
                <ListGroup.Item>Phone number</ListGroup.Item>
                <ListGroup.Item>Address, State, Province, ZIP/Postal code, City</ListGroup.Item>
                <ListGroup.Item>Usage Data</ListGroup.Item>
              </ListGroup>

              <Card.Text className="mt-3">
                <strong>Usage Data</strong> Usage Data is collected automatically when using the Service. This may include information such as your Device's Internet Protocol address (e.g., IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers, and other diagnostic data.
              </Card.Text>
              <Card.Text>
                When you access the Service via a mobile device, we may collect certain information automatically, including but not limited to, the type of mobile device you use, your mobile device's unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers, and other diagnostic data.
              </Card.Text>
              <Card.Text>
                We may also collect information that your browser sends whenever you visit our Service or when you access the Service through a mobile device.
              </Card.Text>

              <Card.Text>
                <strong>Information from Third-Party Social Media Services</strong> The Company allows you to create an account and log in to use the Service through the following Third-Party Social Media Services:
              </Card.Text>
              <ListGroup>
                <ListGroup.Item>Google</ListGroup.Item>
                <ListGroup.Item>Facebook</ListGroup.Item>
                <ListGroup.Item>Instagram</ListGroup.Item>
                <ListGroup.Item>Twitter</ListGroup.Item>
                <ListGroup.Item>LinkedIn</ListGroup.Item>
              </ListGroup>
              <Card.Text>
                If you decide to register through or otherwise grant us access to a Third-Party Social Media Service, we may collect Personal Data that is already associated with your Third-Party Social Media Service account, such as your name, email address, activities, or contact list associated with that account.
              </Card.Text>
              <Card.Text>
                You may also have the option of sharing additional information with the Company through your Third-Party Social Media Service account. If you choose to provide such information and Personal Data during registration or otherwise, you are giving the Company permission to use, share, and store it in a manner consistent with this Privacy Policy.
              </Card.Text>

              <Card.Text>
                <strong>Tracking Technologies and Cookies</strong> We use Cookies and similar tracking technologies to track activity on our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze our Service. The technologies we use may include:
              </Card.Text>
              <ListGroup>
                <ListGroup.Item>
                  <strong>Cookies or Browser Cookies.</strong> A cookie is a small file placed on your device. You can instruct your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if you do not accept Cookies, you may not be able to use some parts of our Service. Unless you have adjusted your browser settings to refuse Cookies, our Service may use Cookies.
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Web Beacons.</strong> Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (e.g., recording the popularity of a certain section and verifying system and server integrity).
                </ListGroup.Item>
              </ListGroup>

              <Card.Text>
                We use both Session and Persistent Cookies for the following purposes:
              </Card.Text>
              <ListGroup>
                <ListGroup.Item>
                  <strong>Necessary / Essential Cookies</strong>
                  <p>Type: Session Cookies</p>
                  <p>Administered by: Us</p>
                  <p>Purpose: These Cookies are essential to provide you with services available through the Website and to enable you to use some of its features. They help authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services you have requested cannot be provided, and we only use these Cookies to provide you with those services.</p>
                </ListGroup.Item>
                {/* Add more cookie types and purposes as needed */}
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PrivacyPolicy;
