import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { Container } from 'react-bootstrap';
import StudentRegistrationForm from './StudentRegistrationForm';
import Events from './Events';

const Layout = ({ children }) => {
  return (
    <div>
      <Header />
      <main>
        <Container className="py-5">{children}</Container>
        
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
