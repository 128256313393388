import React from 'react';

const ShippingAndDelivery = () => {
    return (
        <div style={{ padding: '20px', backgroundColor: '#f9f9f9', borderRadius: '8px', marginTop: '20px' }}>
            <h2>Shipping and Delivery</h2>
            
            <h3>Order Processing</h3>
            <p>All orders are processed within 1-2 business days (excluding weekends and holidays) after receiving your order confirmation email. You will receive another notification when your order has shipped.</p>
            <p>Orders placed after 12:00 PM will be processed on the next business day.</p>

            <h3>Shipping Rates and Delivery Estimates</h3>
            <p>Shipping charges for your order will be calculated and displayed at checkout. Delivery times are estimated based on your location:</p>
            <ul>
                <li><strong>Standard Shipping:</strong> 5-7 business days</li>
                <li><strong>Express Shipping:</strong> 2-3 business days</li>
                <li><strong>Overnight Shipping:</strong> 1-2 business days</li>
            </ul>
            <p>Delivery delays can occasionally occur, especially during peak seasons or due to unforeseen circumstances such as natural disasters.</p>

            <h3>Shipping to P.O. Boxes</h3>
            <p>We currently do not ship to P.O. boxes. Please provide a physical address for delivery to ensure your order is shipped without delays.</p>

            <h3>International Shipping</h3>
            <p>At this time, we only ship within [Country Name]. If you are interested in international shipping, please contact our customer service for more information.</p>

            <h3>Order Tracking</h3>
            <p>Once your order has shipped, you will receive a tracking number via email. You can use this number to track your package through the carrier’s website.</p>

            <h3>Shipping Confirmation and Order Tracking</h3>
            <p>You will receive a shipping confirmation email once your order has shipped containing your tracking number(s). The tracking number will be active within 24 hours.</p>

            <h3>Customs, Duties, and Taxes</h3>
            <p><strong>Jagarani.in</strong> is not responsible for any customs and taxes applied to your order. All fees imposed during or after shipping are the responsibility of the customer (tariffs, taxes, etc.).</p>

            <h3>Damages</h3>
            <p><strong>Jagarani.in</strong> is not liable for any products damaged or lost during shipping. If you received your order damaged, please contact the shipment carrier to file a claim. Please save all packaging materials and damaged goods before filing a claim.</p>

            <h3>Returns and Refunds</h3>
            <p>For information about returns and refunds, please visit our <a href="https://jagarani.in/refund-policy">Returns and Refunds</a> page.</p>

            <h3>Contact Us</h3>
            <p>If you have any questions or concerns regarding your shipment or delivery, please contact our customer support team at <a href="mailto:support@jagarani.in">support@jagarani.in</a>.</p>
        </div>
    );
};

export default ShippingAndDelivery;
