import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer style={{ backgroundColor: '#ff0000' }} className="text-white text-center py-4">
      <Container>
        <Row className="mb-3">
          <Col>
            <h5>Follow Us</h5>
            <div>
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="text-white mx-2">
                <FaFacebook size={30} />
              </a>
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="text-white mx-2">
                <FaTwitter size={30} />
              </a>
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="text-white mx-2">
                <FaInstagram size={30} />
              </a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
          <p className="d-flex justify-content-center mb-3">
  <span className="text-white">
    <Link to="/terms-and-conditions" className="text-white mx-2">
      Terms and Conditions
    </Link> 
    |
    <Link to="/privacy-policy" className="text-white mx-2">
      Privacy Policy
    </Link> 
    |
    <Link to="/refund-policy" className="text-white mx-2">
      Refund and Cancellation Policy
    </Link> 
    |
    <Link to="/disclaimer" className="text-white mx-2">
      Disclaimer
    </Link> 
    |
    <Link to="/shipping-and-delivery" className="text-white mx-2">
      Shipping and Delivery
    </Link>
  </span>
</p>
             <p>Address:- PURBA BISHNUPUR , CHAKDAH, NADIA, WEST BENGAL, PIN:- 741223| Business Name:-Mukesh Halder, Phone:-9832222142</p>

            <p>&copy; {new Date().getFullYear()} Cultural Event. All rights reserved.</p>
            <p>
              Designed with <span role="img" aria-label="love">❤️</span> by Your Company.
            </p>
            
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
