import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const StudentRegistrationForm = ({ formData, setFormData }) => {
  const [localFormData, setLocalFormData] = useState(formData);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { id, value } = e.target;
    setLocalFormData((prevData) => ({
      ...prevData,
      [id]: value
    }));
  };

  const validateForm = () => {
    for (const key in localFormData) {
      if (localFormData[key].trim() === '') {
        return false;
      }
    }
    return true;
  };

  const handleNext = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setFormData(localFormData);
      navigate('/confirm');
      setError('');
    } else {
      setError('Please fill in all fields.');
    }
  };

  return (
    <Container className="py-5">
      <Row className="justify-content-center">
        <Col md={8}>
          <h2 className="text-center mb-4">
            মেধা আনুসন্ধান পরীক্ষা -২০২৪
            <h3>আয়োজক : জাগরণী </h3>
            <h3>বিষ্ণুপুর, চাকদহ, নদীয়া  </h3>
            <h3>যোগাযোগ:-9564453080/ 9800350619/ 9434824662 </h3>
          </h2>
          
          {error && <Alert variant="danger">{error}</Alert>}

          <Form onSubmit={handleNext}>
            <Form.Group controlId="studentName">
              <Form.Label>Student Name</Form.Label>
              <Form.Control type="text" placeholder="Enter student name" value={localFormData.studentName} onChange={handleChange} />
            </Form.Group>

            <Form.Group controlId="fatherName">
              <Form.Label>Father's Name</Form.Label>
              <Form.Control type="text" placeholder="Enter father's name" value={localFormData.fatherName} onChange={handleChange} />
            </Form.Group>

            <Form.Group controlId="address">
              <Form.Label>Address</Form.Label>
              <Form.Control as="textarea" rows={3} placeholder="Enter address" value={localFormData.address} onChange={handleChange} />
            </Form.Group>

            <Form.Group controlId="phone">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control type="tel" placeholder="Enter phone number" value={localFormData.phone} onChange={handleChange} />
            </Form.Group>

            <Form.Group controlId="dateOfBirth">
              <Form.Label>Date of Birth</Form.Label>
              <Form.Control type="date" value={localFormData.dateOfBirth} onChange={handleChange} />
            </Form.Group>

            <Form.Group controlId="schoolName">
              <Form.Label>School Name</Form.Label>
              <Form.Control type="text" placeholder="Enter school name" value={localFormData.schoolName} onChange={handleChange} />
            </Form.Group>

            <Form.Group controlId="section">
              <Form.Label>Section</Form.Label>
              <Form.Control type="text" placeholder="Enter section" value={localFormData.section} onChange={handleChange} />
            </Form.Group>

            <Form.Group controlId="roll">
              <Form.Label>class</Form.Label>
              <Form.Control type="text" placeholder="Enter Class Name" value={localFormData.roll} onChange={handleChange} />
            </Form.Group>

            <Button variant="primary" type="submit" className="mt-4">
              Next
            </Button>
          </Form><br/>
          <h3>Read Before Submiting the form</h3>
          <p>We are the 'Jagarani Club'. Since 2014, we have been conducting "Medha-Anusandhan Poriksha" (aptitude test) for the students from class 3 to class 10 for evaluation of their textbooks. A large number of students from almost every school in the surrounding area participated in this examination. We charge only Rs.50 per student for conducting this exam, the entire amount of which is spent for the purpose of this exam.</p>
        </Col>
      </Row>
    </Container>
  );
};

export default StudentRegistrationForm;
