import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './Events.css'; // Import the CSS file

const Events = () => {
  return (
    <Container id="events" className="py-5 bg-light">
      <h2 className="text-center mb-4">The Events of jagarani</h2>
      
      {/* Coming Soon Event Highlight */}
      <Row className="mb-5">
        <Col md={{ span: 8, offset: 2 }}>
          <Card className="bg-warning text-white">
            <Card.Body>
              <Card.Title className="text-center">Coming Soon: Medha-Anusandhan Poriksha</Card.Title>
              <Card.Text className="text-center">
                Prepare for the upcoming Medha-Anusandhan Poriksha exam for students from class 3 to 10. Stay tuned for dates and more details!
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Other Events */}
      <Row>
        <Col md={4}>
          <Card className="mb-4">
            <Card.Img className="event-img" variant="top" src="https://firebasestorage.googleapis.com/v0/b/jagoron-cd550.appspot.com/o/blooddonation2.jpeg?alt=media&token=f8502c42-34e4-40d9-ac1a-d55d37e55570" />
            <Card.Body>
              <Card.Title>Blood Donation Camp</Card.Title>
              <Card.Text>
                Participate in our blood donation camp and help save lives. Join us for this noble cause.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="mb-4">
            <Card.Img className="event-img" variant="top" src="https://firebasestorage.googleapis.com/v0/b/jagoron-cd550.appspot.com/o/cultural%20event2.jpeg?alt=media&token=b5047f7b-bdc6-4d4e-bac3-7cbe20e2adf7" />
            <Card.Body>
              <Card.Title>Yearly Cultural Event</Card.Title>
              <Card.Text>
                Celebrate our cultural heritage with performances, music, and drama in our yearly event.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="mb-4">
            <Card.Img className="event-img" variant="top" src="https://firebasestorage.googleapis.com/v0/b/jagoron-cd550.appspot.com/o/football.jpeg?alt=media&token=9cb7dde9-d797-45b7-ab07-ab8bab573680" />
            <Card.Body>
              <Card.Title>Football & Cricket Tournament</Card.Title>
              <Card.Text>
                Show your sportsmanship in our annual football and cricket tournaments. Everyone is welcome to participate.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Card className="mb-4">
            <Card.Img className="event-img" variant="top" src="https://firebasestorage.googleapis.com/v0/b/jagoron-cd550.appspot.com/o/sciencefair.jpeg?alt=media&token=182cde4e-239a-489d-95dd-4acd14ae894a" />
            <Card.Body>
              <Card.Title>Science Fair</Card.Title>
              <Card.Text>
                Discover innovative projects by young minds at our annual science fair. A platform for creativity and knowledge.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="mb-4">
            <Card.Img className="event-img" variant="top" src="https://firebasestorage.googleapis.com/v0/b/jagoron-cd550.appspot.com/o/music.jpeg?alt=media&token=4d9662bb-babc-4ebe-aa54-6a6ff1c6c6d2" />
            <Card.Body>
              <Card.Title>Music Concert</Card.Title>
              <Card.Text>
                Enjoy performances by renowned musicians from various genres.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="mb-4">
            <Card.Img className="event-img" variant="top" src="https://firebasestorage.googleapis.com/v0/b/jagoron-cd550.appspot.com/o/Art.jpeg?alt=media&token=ea38faca-8633-40c8-8086-b7892de51453" />
            <Card.Body>
              <Card.Title>art exhibition</Card.Title>
              <Card.Text>
                Watch stunning dance performances that showcase cultural heritage.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Events;
