import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const NewPage = () => {
  const backgroundImage = {
    backgroundImage: 'url(https://firebasestorage.googleapis.com/v0/b/jagarani-c3a5c.appspot.com/o/Cover.png?alt=media&token=ad3ba944-1563-48f0-9ac6-e183a25c6baf)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: '100vh', // Full viewport height
   
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0', // Remove any default margin
    padding: '0', // Remove any default padding
  };

  return (
    <div style={backgroundImage} className="text-white text-center">
      <Container fluid className="p-0 m-0"> {/* Set padding and margin to 0 */}
        <Row className="justify-content-center m-0"> {/* Remove row margin */}
          <Col md={8}>
            <h1 className="display-4">Welcome to the Cultural Event</h1>
            <p className="lead">
              Join us for an unforgettable experience filled with music, dance, and art.
            </p>
            
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NewPage;
