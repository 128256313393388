import React from 'react';

const About = () => {
  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-md-10 offset-md-1">
          <h1 className="display-4 text-center mb-4">About Us</h1>
          <p className="lead text-center"><strong>Welcome to Jagarani!</strong></p>
          <p>
            Established in 1957, Jagarani has been a beacon of culture, sports, and community spirit for over six decades.
            Our club was founded with the vision of creating a space where the love for culture and sports could thrive,
            bringing together people of all ages and backgrounds.
          </p>

          <h2 className="mt-4">Our Mission</h2>
          <p>
            At Jagarani, our mission is to foster a sense of community through the celebration of cultural diversity and
            the promotion of sportsmanship. We believe in the power of culture and sports to unite people, nurture talents,
            and build a stronger, more vibrant society.
          </p>

          <h2 className="mt-4">What We Do</h2>
          <p><strong>Cultural Events:</strong></p>
          <p>
            Jagarani is proud to host a variety of cultural events that showcase the rich heritage and traditions of our
            community. From vibrant <strong>Baul Mela</strong> festivals celebrating the soulful Baul music, to engaging
            <strong> Science Fairs</strong> that ignite curiosity and innovation, our events are designed to entertain,
            educate, and inspire.
          </p>

          <p><strong>Sports Activities:</strong></p>
          <p>
            We are passionate about sports and believe in the spirit of healthy competition. Jagarani organizes regular
            <strong> Football</strong> and <strong>Cricket</strong> tournaments, providing a platform for both aspiring
            athletes and seasoned players to showcase their skills. Our sporting events are a testament to our commitment
            to physical fitness, teamwork, and fair play.
          </p>

          <h2 className="mt-4">Join Us</h2>
          <p>
            Jagarani is more than just a club; it is a community where everyone is welcome. Whether you are a cultural
            enthusiast, a sports lover, or someone looking to connect with like-minded individuals, Jagarani has something
            for you. Come join us in celebrating the joys of culture, the thrill of sports, and the strength of community.
          </p>

          <p className="lead mt-4 text-center"><strong>Let's build the future together, while honoring our rich past.</strong></p>
          <p className="text-center"><strong>Jagarani - Where Culture Meets Sports!</strong></p>
        </div>
      </div>
    </div>
  );
};

export default About;
