// src/pages/RefundPolicy.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const RefundPolicy = () => {
  return (
    <Container className="my-4">
      <Row>
        <Col>
          <h1 className="mb-4">Refund Policy</h1>
          <p><strong>Last updated: August 12, 2024</strong></p>
          <p>Thank you for registering with us. We strive to provide a seamless and satisfactory experience for all our users. Please review our Refund Policy carefully to understand how we handle refunds for the registration fees.</p>
          
          <h2 className="mt-4">Registration Fee</h2>
          <p>Our website charges a non-refundable registration fee of ₹50 per form submitted. This fee is required to process and handle your registration. Once the payment is made, it is processed immediately and is non-refundable.</p>

          <h2 className="mt-4">Refund Policy</h2>
          <ul>
            <li><strong>General Rule:</strong> All registration fees are non-refundable. Once the payment is made and the form is submitted, no refunds will be issued.</li>
            <li><strong>Exceptions:</strong> Refunds may be considered in exceptional circumstances such as:
              <ul>
                <li><strong>Duplicate Payment:</strong> If you have accidentally made a duplicate payment for the same registration, please contact us within 7 days of the payment. We will review the request and, if valid, process a refund for the duplicate amount.</li>
                <li><strong>Technical Issues:</strong> If you encounter a technical error during registration that prevents successful submission and payment, please contact our support team within 7 days of the error. We will investigate the issue and, if confirmed, provide a refund or assist with re-registration.</li>
              </ul>
            </li>
            <li><strong>Requesting a Refund:</strong> To request a refund, please contact us at <a href="mailto:jagarani1957@gmail.com">jagarani1957@gmail.com</a> or visit our <Link to="/contact">Contact Us page</Link>. Provide details of your transaction and the reason for the refund request. All refund requests will be reviewed on a case-by-case basis.</li>
            <li><strong>Processing Time:</strong> Refund requests, if approved, will be processed within 7-10 business days from the date of approval. Refunds will be issued to the original payment method used during registration.</li>
          </ul>

          <h2 className="mt-4">Contact Us</h2>
          <p>If you have any questions regarding this Refund Policy or need to request a refund, please contact us at:</p>
          <ul>
            <li><strong>Email:</strong> <a href="mailto:jagarani1957@gmail.com">jagarani1957@gmail.com</a></li>
            <li><strong>Phone:</strong> 9564453080</li>
            <li><strong>Contact Us Page:</strong> <Link to="/contact">https://jagarani.in/contact</Link></li>
          </ul>

          <h2 className="mt-4">Changes to This Policy</h2>
          <p>We may update this Refund Policy from time to time. Any changes will be posted on this page, and we will notify you of significant changes through email or a prominent notice on our website.</p>
          <p>Please review this policy periodically to stay informed about how we handle refunds.</p>
        </Col>
      </Row>
    </Container>
  );
};

export default RefundPolicy;
