// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCTt_Y8-OPzGgqdNgmioKX1BAsTS5IG-C8",
    authDomain: "jagoron-cd550.firebaseapp.com",
    projectId: "jagoron-cd550",
    storageBucket: "jagoron-cd550.appspot.com",
    messagingSenderId: "4927788661",
    appId: "1:4927788661:web:d6d0296f757daa9e400a3c"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
