import React, { useRef, useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { db } from './firebase'; // Import Firebase database instance
import { collection, query, where, getDocs } from 'firebase/firestore';
import { toast } from 'react-toastify'; // Import toast
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { useReactToPrint } from 'react-to-print';
import './pdfsty.css';
const SearchPage = () => {
  const [studentName, setStudentName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const componentRef = useRef(); // Ref for printing and PDF generation

  const handleSearch = async (e) => {
    e.preventDefault();
    setLoading(true);
    setData(null);

    try {
      const q = query(
        collection(db, 'students'),
        where('studentName', '==', studentName),
        where('dateOfBirth', '==', dateOfBirth)
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        querySnapshot.forEach((doc) => {
          setData(doc.data());
        });
        toast.success('Student details found!');
      } else {
        toast.error('No such document!');
      }
    } catch (error) {
      console.error('Error fetching document:', error);
      toast.error('Error fetching document.');
    } finally {
      setLoading(false);
    }
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleGeneratePDF = () => {
    const input = componentRef.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save('student-details.pdf');
    });
  };
  

  return (
    <Container className="py-5">
      <Row className="justify-content-center">
        <Col md={8}>
          <h2 className="text-center mb-4">Download Admit Card</h2>
          <Form onSubmit={handleSearch}>
            <Form.Group controlId="studentName">
              <Form.Label>Student Name</Form.Label>
              <Form.Control
                type="text"
                value={studentName}
                onChange={(e) => setStudentName(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group controlId="dateOfBirth" className="mt-3">
              <Form.Label>Date of Birth</Form.Label>
              <Form.Control
                type="date"
                value={dateOfBirth}
                onChange={(e) => setDateOfBirth(e.target.value)}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-4" disabled={loading}>
              {loading ? 'Searching...' : 'Search'}
            </Button>
          </Form>
          {data && (
            <div className="mt-4" ref={componentRef} style={{ backgroundColor: '#fff', padding: '20px', borderRadius: '8px', boxShadow: '0 0 10px rgba(0,0,0,0.1)' }}>
                <div class="container custom-border">
                    <div class="row">
                        <div class="col-2">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/jagarani-c3a5c.appspot.com/o/logo192.jpeg?alt=media&token=67d16203-6067-40a7-ba6d-af5ae4d95149"
                          alt="Logo"
                          className="hanging-logo"
                        />

                        </div>
                        <div class="col-10">
                        <h3 className="text-center mb-4">
                           মেধা আনুসন্ধান পরীক্ষা -২০২৪
                           <h4>আয়োজক : জাগরণী </h4>
                           <h6>বিষ্ণুপুর, চাকদহ, নদীয়া  </h6>
                           <h6>যোগাযোগ:-9564453080/ 9800350619/ 6434824662 </h6>
                        </h3>

                        </div>
                    </div>
                </div>
              
          <div className="text-center mb-4">
            <div class="container custom-border">
                <div class="row">
                    <div class="col-6">
                    <p><strong>Student Name:</strong> {data.studentName}</p>
                    </div>
                    <div class="col-6">
                    <p><strong>Father's Name:</strong> {data.fatherName}</p>
                    </div>
                    

                </div>
                <div class="row">
                    <div class="col-6">
                    <p><strong>Phone Number:</strong> {data.phone}</p>
                    </div>
                    
                    <div class="col-6">
                    <p><strong>Date of Birth:</strong> {data.dateOfBirth}</p>
                    </div>

                </div>
                <div class="row">
                    <div class="col-6">
                    <p><strong>School Name:</strong> {data.schoolName}</p>
                    </div>
                    
                    <div class="col-6">
                    <p><strong>Section:</strong> {data.section}</p>
                    </div>

                </div>
                <div class="row">
                    <div class="col-6">
                    <p><strong>Class:</strong> {data.roll}</p>
                    </div>
                    
                    <div class="col-6">
                    <p><strong>Payment ID:</strong> {data.paymentId}</p>
                    </div>

                </div>
                <div class="row">
                    <div class="col-6">
                    <p><strong>Amount:</strong> ₹{data.amount}</p>
                    </div>
                    
                    <div class="col-6">
                    <p><strong>Date:</strong> {data.date}</p>
                    </div>

                </div>

            </div><br/>
            <div></div>
              
              
              
              
              
              
              
              
             
              
              
              
              <div class="container ">
                <div class="row custom-border">
                <div class="col-2">
                <img
              src="https://firebasestorage.googleapis.com/v0/b/jagarani-c3a5c.appspot.com/o/logo192.jpeg?alt=media&token=67d16203-6067-40a7-ba6d-af5ae4d95149"
              alt="Logo"
              className="hanging-logo"
            />
                </div>
                <div class="col-8">
                <h3 className="text-center mb-4">
                           মেধা আনুসন্ধান পরীক্ষা -২০২৪
                           <h4>আয়োজক : জাগরণী </h4>
                           <h6>বিষ্ণুপুর, চাকদহ, নদীয়া  </h6>
                           <h6>যোগাযোগ:-9564453080/ 9800350619/ 6434824662 </h6>
                        </h3>

                </div>
                <div class="col-2 text-left">
                <p class="text-sm-end"><span class="avatar  rounded-5 text-bg-secondary">{data.roll}</span> </p>
                </div>
                

                </div>
                <div class="row custom-border-right-main">
                    <div class="col-1"></div>
                    <div class="col-10">
                    <p><strong>Exam Roll No:</strong> {data.documentId}</p>
                    <p><strong>Student Name:</strong> {data.studentName}</p>
                    <p><strong>Father's Name:</strong> {data.fatherName}</p>
                    <p><strong>School Name:</strong> {data.schoolName}</p>
                    <p><strong>Class:</strong> {data.roll}</p>
                    </div>
                    <div class="col-1"></div>

                </div>
                <div class="row custom-border p-3">
                  <div class="col-4 custom-border-right">
                   <p><strong>পরীক্ষা কেন্দ্র: </strong> বিষ্ণুপুর হাই স্কুল</p>
                  </div>
                  <div class="col-4 custom-border-right">
                   <p><strong>পরীক্ষার তারিক: </strong> ০১/০৯/২০২৪</p>
                  </div>
                  <div class="col-4">
                   <p><strong>সময়: </strong> বেলা ১১-১</p>
                  </div>
                </div>
              </div>
              
              </div>
            </div>
          )}
        </Col>
        <div className="text-center mt-4">
                <Button variant="secondary" onClick={handlePrint} className="mr-2">
                  Print
                </Button>
                
              </div>
      </Row>
    </Container>
  );
};

export default SearchPage;
