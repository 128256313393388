import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { db } from './firebase'; // Import Firebase database instance
import { collection, addDoc } from 'firebase/firestore';
import { toast } from 'react-toastify'; // Import toast

const ConfirmDetails = ({ formData }) => {
  const navigate = useNavigate();
  const amount = 50; // Example amount, replace with actual amount if dynamic

  const handleConfirmRegistration = () => {
    const options = {
      key: 'rzp_test_mnkHnUgQTagbTE', // Replace with your Razorpay key
      amount: amount * 100, // Amount in paise
      currency: 'INR',
      name: 'Exam Fees',
      description: 'Payment for exam registration',
      handler: async (response) => {
        const paymentId = response.razorpay_payment_id;
        const today = new Date();
        const formattedDate = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
        const documentId = `${Date.now()}`;
        try {
          // Add document to Firestore if payment is successful
          await addDoc(collection(db, 'students'), {
            ...formData,
            paymentId: paymentId,
            date: formattedDate,
            documentId: documentId,
            amount
          });
          toast.success('Payment successful and registration completed!');
          navigate('/print', { state: { dateOfBirth: formData.dateOfBirth, studentName: formData.studentName } });
        } catch (error) {
          console.error('Error adding document:', error.message);
          toast.error('Error completing registration. Please try again.');
        }
      },
      theme: {
        color: '#3399cc'
      }
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  const handleEdit = () => {
    navigate('/student'); // Redirect back to the form page
  };

  return (
    <Container className="py-5">
      <Row className="justify-content-center">
        <Col md={8}>
          <h2 className="text-center mb-4">Confirm Your Details</h2>
          <div>
            <p><strong>Student Name:</strong> {formData.studentName}</p>
            <p><strong>Father's Name:</strong> {formData.fatherName}</p>
            <p><strong>Address:</strong> {formData.address}</p>
            <p><strong>Phone Number:</strong> {formData.phone}</p>
            <p><strong>Date of Birth:</strong> {formData.dateOfBirth}</p>
            <p><strong>School Name:</strong> {formData.schoolName}</p>
            <p><strong>Section:</strong> {formData.section}</p>
            <p><strong>Class:</strong> {formData.roll}</p>
          </div>
          <Button variant="primary" onClick={handleConfirmRegistration} className="mt-4">
            Confirm
          </Button>
          <Button variant="secondary" onClick={handleEdit} className="mt-4 ml-3">
            Edit
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default ConfirmDetails;
